.proj-description__container {
  /* background-color: white; */
  color: white;
  margin-top: auto;
  position: relative;
  bottom: 20px;
  /* border: solid red; */
}
@media (max-width: 950px) {
  .proj-description__container {
    bottom: 0px;
  }
  .proj-description__container p {
    font-size: 12px;
  }
}
@media (max-width: 400px) {
  .proj-description__container {
    bottom: 0px;
  }
  .proj-description__container p {
    font-size: 11px;
  }
}
