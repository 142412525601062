.about__container {
  height: 100vh;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.about__container p {
  font-size: 30px;
  padding: 20px;
}
@media (max-width: 950px) {
  .about__container p {
    font-size: 20px;
    padding: 20px;
    animation-name: colorIfy;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  .about__container {
    justify-content: flex-start;
  }
}
@keyframes colorIfy {
  from {
    color: white;
  }
  to {
    color: red;
  }
}
.mobileOnly {
  display: none;
}

.mobileNavBox {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  /* border: solid red; */
}
.box-div {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 40%;
  border: solid red;
}
.box-div:hover {
  background-color: red;
}

@media (max-width: 950px) {
  .mobileOnly {
    display: flex;
  }

  .mobileNavBox {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    /* border: solid red; */
  }
  .box-div {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 40%;
    border: solid red;
  }
}

@media (max-width: 400px) {
  .about__container p {
    font-size: 15px;
    padding: 20px;
    animation-name: colorIfy;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}
