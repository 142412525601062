.stack__container {
  /* background-color: red; */
  width: 100px;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border: solid red;
  align-items: center;
}
.proj__img {
  height: 60px;
  max-width: 90px;
}
@media (max-width: 950px) {
  .stack__container {
    /* background-color: red; */
    width: 40px;
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border: solid red;
    align-items: center;
  }
  .proj__img {
    height: 30px;
    max-width: 40px;
  }
}
@media (max-width: 400px) {
  .stack__container {
    /* background-color: red; */
    width: 40px;
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border: solid red;
    align-items: center;
  }
  .proj__img {
    height: 30px;
    max-width: 35px;
  }
}
