.app-store-icon-split {
  display: flex;
  justify-content: space-around;
  position: relative;
  top: 5px;
}
.project__icon {
  height: 75px;
  width: 75px;
  border-radius: 10%;
}
.project__app-store {
  /* height: 0px; */
  width: 200px;
}
@media {
  .project__app-store {
    width: 100px;
  }
  .project__icon {
    height: 35px;
    width: 35px;
    border-radius: 10%;
  }
}
