.project__container {
  height: 100vh;
  color: white;
  text-align: -webkit-center;
}

.screen-split {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.project__right-split {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.right {
  margin-right: 10px;
}
.left {
  margin-left: 10px;
}

@media (max-width: 950px) {
}
