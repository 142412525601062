.skills__container {
  height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 950px) {
  .skills__container {
    justify-content: flex-start;
  }
  .arrow__holder {
    /* margin-top: auto; */
    position: relative;
    top: 20px;
  }
}

@media (max-width: 400px) {
  .arrow__holder {
    /* margin-top: auto; */
    position: relative;
    top: 60px;
  }
}
