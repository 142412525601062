.navBar__container {
  height: 50px;
  width: 98vw;
  display: flex;
  color: white;
  justify-content: space-between;
  position: sticky;
  top: 0;
}
.navBar__container p:hover {
  color: red;
}
.left-nav {
  width: 150px;
  padding-left: 10px;
  display: flex;
  background-color: black;
}

.left_nav-move {
  padding-left: 50px;
}
.right-nav {
  width: 150px;
  background-color: black;
  /* padding-right: 50px; */
  display: flex;
}
.right_nav-move {
  padding-right: 50px;
}
a {
  text-decoration: none;
  color: white;
}

@media (max-width: 950px) {
  .navBar__container {
    display: none;
  }
}
