.spinny-div__container {
  /* background-color: red; */
  /* height: 80%; */
  width: 80%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 50px;
}
@media (max-width: 950px) {
  .spinny-div__container {
    position: relative;
    /* top: -50px; */
    width: 100%;
    height: 40vh;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 400px) {
  .spinny-div__container {
    padding: 0;
    top: 50px;
    width: 100%;
  }
  .spinny-div{
    width: 90%;
  }
}
