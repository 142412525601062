.landing__container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
  align-items: center;
}
.showOnMobile {
  display: none;
}
.showOnDesktop {
  display: block;
}

@media (max-width: 950px) {
  .showOnDesktop {
    display: none;
    background-color: blue;
  }
  .showOnMobile {
    display: block;
    background-color: red;
  }
  body {
    margin: 0;
    height: 100%;
    overflow: hidden;
  }
}
