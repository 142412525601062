.carousel__image-holder {
  /* height: 300px; */
}
.carousel__image {
  /* max-height: 400px;
  object-fit: contain; */
}
.carousel * {
  height: 500px;
  object-fit: contain;
  position: relative;
}
.carousel .control-dots {
  position: absolute;
  top: 470px;
  margin: 10px 0;
  padding: 0;
  text-align: center;
  width: 100%;
  z-index: 1;
}
@media (max-width: 950px) {
  .carousel * {
    height: 400px;
  }
}
@media (max-width: 400px) {
  .carousel * {
    height: 200px;
  }
}
