.contact__container {
  height: 100vh;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contact__button {
  position: relative;
  height: 100px;
  border-radius: 70%;
  box-shadow: 0 12px 4px rgba(173, 45, 45, 0.5);
  display: flex;
  align-self: center;
  /* background-color: black; */
}

.contact__button:hover {
  transform: scale(1.3);
  /* transform: scale(0.8); */
  transition: 250ms all;
  transition: all 0.2s ease-in-out;
}
.contact__icon-holders {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  /* animation-name: spinMid;
  animation-duration: 6000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; */
  margin-bottom: 50px;
}

.less {
  border-radius: 5%;
}
@media (max-width: 950px) {
  .contact__icon-holders {
    height: 60vh;
    margin-bottom: 0px;
  }
  .contact__container {
    height: 100vh;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

@media (max-width: 400px) {
  .contact__button {
    position: relative;
    height: 50px;
    border-radius: 70%;
    box-shadow: 0 12px 4px rgba(173, 45, 45, 0.5);
    display: flex;
    align-self: center;
    /* background-color: black; */
  }
  .contact__icon-holders {
    height: 40vh;
    margin-bottom: 0px;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
}
